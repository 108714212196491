@import 'variables';
@import 'functions';
@import 'mixins';
@import 'utils';
@import 'table';
@import 'maps';

// breakpoints
@import './breakpoints/sm';

@media (min-width: 768px) {
  @import './breakpoints/md';
}

@media (min-width: 992px) {
  @import './breakpoints/lg';
}

@media (min-width: 1200px) {
  @import './breakpoints/xl';
}

// css reset
body {
  font-family: 'Inter', 'Segoe UI', 'Source Sans Pro', 'Lato', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  text-rendering: optimizeLegibility;
  background-color: #e3e8e9;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

// General Styles
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  margin-top: 85px;
}

.table-responsive-sm {
  margin-top: 30px;
}

.file--input {
  border-radius: 10px;
  outline: none;
  width: 150px;
  font-size: 0.9rem;
}

::-webkit-file-upload-button {
  color: #fff;
  background-color: teal;
  padding: 8px;
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100vh;
}

/* 
  Override default z-index for toast container 
*/
.react-toast-notifications__container {
  z-index: 1000000000000000000000000000000000 !important;
}

/* Marker Popup */
.mapboxgl-popup-close-button {
  padding: 2%;
  font-size: 25px;
}

.mapboxgl-popup-content {
  min-width: 300px;
  padding-top: 22px;
}

.mapboxgl-popup-content h3 {
  background: #048ba8;
  color: #fff;
  margin: 0;
  display: block;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: bold;
  margin-top: -15px;
}

.mapboxgl-popup-content p {
  margin: 0;
  display: block;
  padding-top: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #646369;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}
